// Breakpoints
$breakpoints: (
  "horizontal": (
    "xs": 375px,
    "sm": 500px,
    "sm-bump": 600px,
    "md": 700px,
    "lg": 960px,
    "xl": 1180px,
    "xxl": 1200px,
    "xxxl": 1400px
  ),
  "vertical": (
    "xs": 300px,
    "sm": 450px,
    "md": 600px,
    "lg": 750px,
    "xl": 900px,
    "xxl": 1100px
  )
);

// Base
$base-font                : "DMSans", Helvetica, Arial, sans-serif; //DM-Sans
$base-font-heading        : "Newsreader", Trebuchet MS, serif; //Newsreader
$base-font-size           : 18px;
$base-font-weight         : 400;
$base-font-heading-weight : 400;
$base-line-height         : 1.5;
$border-radius            : 0;

// Brand
$brand-rosewater          : #FFE4D6; //old coral #f9c29d and coral-lt #FCE5D4
$brand-teal               : #00646B; //old slate #3C646A
$brand-glow               : #C1F5EC; //old slate-lt #e2e8e9;
$brand-glow-faded         : #EAFCF9; //Glow Faded
$brand-coral              : #FF5942; //Coral old marigold #FF9C07
$brand-chalkboard         : #04404A;
$brand-raspberry          : #C71A43; //Raspberry old Seafoam #1ABC9C

$brand-black              : #101515;
$brand-white              : #FBFBFB; //new Snow, old #FFF
$brand-gray               : #666666;
$brand-gray-disabled      : #ACACAC;
$brand-light-gray         : #F0F2F2; //new Silver, old #EBEDEF
$brand-gray-blue          : #EFF2F2;
$brand-bg-gray            : #fdfdfd;

$brand-primary            : $brand-teal; 
$brand-secondary          : $brand-coral; 

// Typography
$text-color               : $brand-black;
$heading-color            : $text-color;
$text-placeholder-color   : #cdcdcd;
$text-gray                : #75787b;
$link-color               : $brand-raspberry;

// Typography sizing
$text-xs                  : 0.667rem; /* 12px */
$text-sm                  : 0.778rem; /* 14px */
$text-sm-bump             : 0.889rem; /* 16px */
$text-base                : 1rem;     /* 18px */
$text-base-bump           : 1.125rem; /* 20px */
$text-md                  : 1.167rem; //21px /* 22px */
$text-24                  : 1.333rem; /* 24px */
$text-md-bump             : 1.556rem; /* 28px */
$text-md-extra            : 1.778rem; /* 32px */
$text-lg                  : 2rem;     /* 36px */
$text-xl                  : 2.667rem; /* 48px */
$text-xxl                 : 5rem;     /* 90px */

$text-21                  : 1.167rem; //21px
$text-30                  : 1.667rem; //30px
$text-38                  : 2.111rem; //38px

$letter-spacing-default-wide   : 0.125em;

// Spacing
$space-xs                 : 5px;
$space-sm                 : 10px;
$space                    : 20px;
$space-bump               : 30px;
$space-md                 : 40px;
$space-lg                 : 60px;
$space-xl                 : 100px;
$space-xxl                : 150px;

// Bg Colors
$bg-color                 : $brand-white;
$bg-footer                : $brand-chalkboard;

// Borders
$border-color             : $brand-light-gray;
$hr-color                 : $border-color;

// Grid
$grid-padding             : 25px;

// Wrapper
$wrapper-padding          : 30px;
$wrapper-narrow           : get-breakpoint("horizontal", "md");

// Header
$header-height-desktop    : 90px;
$header-height-mobile     : 100px;

// Transitions
$base-transition          : all .3s ease-in-out;
$base-transition-timing   : .3s;
$base-transition-easing   : ease-in-out;
$anim-transition          : all .6s ease-out;
$animlong-transition      : all 1.6s ease-out;

