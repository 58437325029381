/* HOME PAGE STYLES */

@import 'sass/settings/variables';
@import 'sass/settings/functions';
@import 'sass/settings/mixins';

.home-intro {
  padding: 0;

  &.js-home-hero {
    @include media-above('md') {
      opacity: 0;
    }
  }

  &.intro {
    & > .wrapper {
      // height: 0px;
      position: relative;
      z-index: -2;
      padding-bottom: 5vw;
    }

    .sun-icon {
      position: relative;
      top: -$space-bump;
      z-index: -1;
      @include media-below('md') {
        width: 20vw;
        position: absolute;
        right: 0;
        top: -$space-xl;
      }
      @include media-below('sm') {
        width: 30vw;
      }

    }

    .intro__title {
      margin-top: 0.5rem;
      max-width: 100%;
      @include media-above('sm') {
        min-height: 0;
      }
    }

    .intro__content {
      padding-top: 0;
      max-width: 22em;
    }

    .intro__home-bg-img {

      display: block;
      width: 100%;
      z-index: -1;
      left: 0;
      top: 100%;
      height: 120px;
      position: absolute;
      text-align: right;

      svg {
        width: 120px;
        height: 120px;
      }
    }

    @include media-above('md') {
      padding-top: $space-lg;
      position: relative;
      & > .wrapper {
        padding-bottom: 0;
      }
      .intro__content {
        padding-top: $space-md;
      }
      .intro__home-bg-img {
        opacity: 0;
        // top: 200%;
        right: -15%;
        left: auto;
        text-align: center;
        padding-top: $space-md;
      }
    }
    @include media-above('xl') {
      padding-top: $space-xl;

      .intro__content {
        p {
          font-size: $text-base;
          max-width: 19em;

        }
      }
      .intro__home-bg-img {
        height: 190px;
        // top: 200%;
        // right: 15%;
        text-align: right;
        padding-right: $space-xl;

        svg {
          width: 190px;
          height: 190px;
        }
      }
    }
    @include media-above('xxxl') {
      .intro__content {
        p {
          max-width: 22em;
          
        }
      }
      .intro__home-bg-img {
        padding-right: 0;
        padding-top: 0;
      }
    }
  }

  &__bg-top {
    position: relative;
    // height: 45px;
    height: 20vw;

    .bg-light,
    .bg-medium,
    .bg-dark {
      width: 100%;
      height: auto;
      position: absolute;
      z-index: -1;
      transition: $anim-transition;

      svg {
        width: 100%;
      }
    }

    .bg-light {
      top: 0;
    }

    .bg-medium {
      top: 4vw;
    }

    .bg-dark {
      display: block;
      bottom: -1px;
    }
  }

  &__columns {
    padding: $space 0 $space-md;
    text-align: center;

    .js-home-hero-featured {
      opacity: 0;
    }

    .grid__column:first-child {
      padding-top: $space;
      padding-bottom: $space;
    }

    h2 {
      margin-bottom: $space-sm;
    }

    .wysiwyg-editor {
      text-align: left;
    }

    .button-group {
      align-items: center;
    }

    @include media-above('md') {
      text-align: left;
      .grid {
        justify-content: center;
      }
      .grid__column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      h2 {
        text-align: left;
      }
      .button--text {
        text-align: left;
      }
      .button-group {
        align-items: flex-start;
      }
    }
    @include media-above('xl') {
      .grid {
        margin: 0 (-$space-bump);
      }
      .grid__column {
        padding: $space $space-bump $space-md*2;
      }
      .grid__column:first-child {
        padding-bottom: $space-md*2;
      }
      h2 {
        margin-bottom: $space;
      }
      .button-group {
        position: absolute;
        bottom: 0;
        left: $space-bump;
        justify-content: flex-start;

        // a.button--text {
        //   font-size: $text-base;
        // }
      }
    }
  }

  &__icon {
    width: 100px;
    margin-bottom: $space-xs;
    @include media-above('md') {
      display: block;
      width: auto;
      height: 136px;
      margin: 0 auto calc($space-bump / 2);
    }
    @include media-above('xl') {
      margin: 0 auto $space-bump;
    }
  }

  &__bg-bottom {
    display: block;
    width: 250%;
    margin-left: -5%;
    margin-top: -1px;

    @include media-above('md') {
      width: 110%;
      position: absolute;
      top: 100%;
      margin-left: 0;
      left: -5%;
      z-index: 1;
    }
  }
}

.home-work {
  padding: 0;

  .grid__column {
    padding: $space-lg $space;
  }

  &__images-wrap {
    position: relative;
  }

  &__border {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    &--left {
      width: 50%;
      cursor: url(/img/left-arrow.svg), auto;
    }

    &--right {
      width: 50%;
      cursor: url(/img/right-arrow.svg), auto;
    }
  }

  &__image {
    position: absolute;
    width: calc(100% - 40px);
    height: 0;
    padding-bottom: calc(100% - 40px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-position: left top;
    opacity: 0;
    transition: $anim-transition;
    overflow: hidden;

    img {
      aspect-ratio: 1 / 1.05;
      object-fit: cover;
      object-position: left top;
    }

    &.is-hovered {
      opacity: 1;
      transition: $anim-transition;
    }

  }

  @include media-above('md') {
    .grid__column {
      padding: $space-xl $space $space-lg;

      &:first-child {
        padding-top: $space-xl*1.6;
      }
    }
    .grid__column:last-child {
      &:after {
        left: 22%;
      }
    }
  }
  @include media-above('xl') {
    .grid__column:last-child {
      padding-right: 0;
    }
    &__image {
      width: calc(100% - 80px);
    }
    &__images-wrap {
      width: 120%;
    }
  }
}

.home-work__single,
.home-thoughts__single {
  width: 100%;
  display: inline-block;
  padding-bottom: 1.5rem;
  color: $text-color;
  transition: $base-transition;

  h3,
  p {
    color: $text-color;
    transition: $base-transition;
  }

  h3 {
    line-height: 1.2;
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-hovered {
    transition: $base-transition;
    color: $brand-raspberry;

    h3 {
      color: $brand-raspberry;
      transition: $base-transition;
    }

    p {
      color: $text-color;
      transition: $base-transition;
    }
  }
}

.home-work__single {
  h3 {
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 12px;
      left: calc(100% + 0.05em);
      top: 50%;
      transform: translateY(-50%);
      transition: inherit;
      opacity: 0;
      background-image: url(/img/design-arrow-right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &.is-hovered {
    h3:after {
      transition: inherit;
      left: calc(100% + 0.3em);
      opacity: 1;
    }
  }
}

.home-thoughts {
  &__content {
    h2 {
      display: inline-block;
      position: relative;
    }
  }

  &__image {
    position: absolute;
    width: 100px;
    top: 50%;
    right: -$space-md;
    transform: translateY(-50%);
    z-index: -1;
  }

  @include media-above('md') {
    &__single:first-child {
      padding-top: 2.5rem;
    }
  }
}

.home-bcorp {
  .grid__column:first-child {
    order: 1;
  }

  &__img-wrap {
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
  }

  &__image {
    position: relative;
    z-index: 1;
  }

  &__decor {
    position: absolute;
    width: 140px;
    top: 50%;
    left: 0;
    transform: translate(20%, 0%);
  }

  @include media-above('md') {
    .grid__column:first-child {
      order: 0;
    }
    &__img-wrap {
      padding: 10% 20%;
    }
    &__decor {
      left: 50%;
      transform: translate(-90%, -10%);
    }
  }
  @include media-above('xl') {
    &__img-wrap {
      padding: $space-sm $space-lg;
      text-align: right;
    }
    &__image {
      width: 140px;
      height: auto;
    }
    &__decor {
      width: 190px;
      transform: translate(-65%, 0%);
    }
  }
}

.home-services,
.how-we-work {
  &__visual {
    position: relative;
  }

  &__number {
    font-family: $base-font-heading;
    font-size: $text-xl*1.5;
    font-weight: 400;
  }

  &__icon {
    display: block;
    width: auto;
    height: 70px;
    position: absolute;
    left: $space;
    top: 50%;
    transform: translateY(-55%);
    z-index: -1;
  }

  @include media-above('md') {
    .grid {
      padding-top: $space;
      padding-left: $space-bump;
    }
    &__single.how-we-work-item {
      height: 100%;
      position: relative;
      padding-bottom: $space-sm*5;

      .button {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  @include media-above('lg') {
    .grid {
      // padding-top: $space;
      margin: 0 (-$space-bump);
    }
    .grid__column {
      padding: 0 $space-bump $space;
    }
    &__number {
      font-size: $text-base-bump*5;
    }
    &__icon {
      height: 95px;
    }
  }
  @include media-above('xl') {
    .grid {
      margin: 0 (-$space);
      padding-left: 0;
    }
    .grid__column {
      padding: 0 $space $space;
    }
    &__single {
      padding-bottom: $space-bump;
    }
  }
}

.home-services {
  .grid {
    justify-content: center;
  }
  .home-services__single.has-link {
    .home-services__link {
      color: $text-color;
    }
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      .home-services__link {
        color: $brand-raspberry;
      }
    }
  }
}

.home-bottom {
  padding-bottom: 0;
  text-align: center;
  margin-bottom: -14%;

  img {
    position: relative;
    z-index: 1;
    width: 98%;
  }
}
